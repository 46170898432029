import React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import Section from '../components/Section'
import TeamCollapse from '../components/TeamCollapse'

const TeamPage = () => (
  <Page
    title='Our People'
    background='images/pages/People.jpg'
  >
    <Container>
      <Section
        title='Our Team'
      >
        <TeamCollapse />
      </Section>
    </Container>
  </Page>
)

export default TeamPage
