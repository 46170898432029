import styled from 'styled-components'

export const Button = styled.button`
  display: block;
  width: 100%;
  background: 0;
  padding: 2em 1em;
  border: 0;
  border-bottom: 1px solid #000;
  text-align: left;
  cursor: pointer;
  outline: none;
`

export const Content = styled.div`
  padding: 2em;
`

export const Photo = styled.img`
  width: 100%;
`

export const Text = styled.p`
  font-weight: 100;
  font-size: 1.25em;
  line-height: 1.5em;
  margin-bottom: 1em;
`
